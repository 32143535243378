<template>
    <div class="container">
        <!-- desktop ranking table  v-if="!isMobile"    -->
        <div class="match-table__wrapper">
            <div v-show="rankingTable.isLoading" class="skeleton-table__wrapper">
            <div class="display-flex-sb mb-1rem" v-for="(o, index) in 10" :key="index">
                <div class="skeleton-child skeleton-table__left"></div>
                <div class="skeleton-child skeleton-table__right"></div>
            </div>
            </div>
            <!-- <Loading v-show="rankingTable.isLoading"></Loading> -->
            <MatchTable
            :isShowTabs="false"
            :type="'total'"
            :matchTableListData="matchTableList"
            v-show="!rankingTable.isLoading && rankingTable.isShow"
            ></MatchTable>
        </div>
        <!-- end desktop ranking table -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MatchTable from "@/components/match/Table-WC-backup.vue";

export default {
  name: "Standings",
  components: {
    MatchTable,
  },
  data() {
    return {
        matchTableList: [],
        rankingTable: {
            isShow: false,
            isShowArrow: true,
            isLoading: false,
            currentSelectedLeagueIndex: 0,
            list: [],
            currentSelectedLeague: "",
        },
    };
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "currentLocale",
      "currentSportType",
      "userInfo",
      "isLogin",
    ]),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["getSoccerLeagueRanking"]),
    init() {
        this.getSoccerLeagueRankingData();
    },
    async getSoccerLeagueRankingData(param) {
      this.rankingTable.isShow= true;
      this.rankingTable.isLoading = true;
      let params = {
        leagueIdList: this.$route.params.leagueId, //''
        groupNameEn: 'Group'
      };
      const result = await this.getSoccerLeagueRanking(params);
      this.getLeagueListForRankingTable(result.result);
      this.rankingTable.isLoading = false;
    },

    //handle ranking table
    getLeagueListForRankingTable(result) {
      if (result.length === 0) {
        this.rankingTable.isShow = false;
      } else {
        this.rankingTable.isShow = true;
        this.rankingTable.list = result;
        this.matchTableList = result;
        // this.handleLeagueListRankingTable();
      }
    },
  },
};
</script>

<style></style>
