<template>
    <div>
        <div v-if="isShowTabs" class="">
            <div class="nav-pill__wrapper mb-main">
                <div class="nav-pill" :class="{selected:o.selected}" v-for="(o, index) in tabs" :key="index" @click="selectTabs(o)">{{o.name}}</div>
            </div>
        </div>

        <div class="display-flex-align-center">
            <div class="icon-league mr-05rem mb-05rem home-match__main-title-flag">
                <img v-if="isWorldCup" :src="`${s3ImgUrl}/football/up/league/world_cup_2022_logo.png`" class="img-contain w-100 h-100" >
                <img v-if="isUefa" :src="`${s3ImgUrl}/football/up/league/uefa_euro_2024.png`" class="img-contain w-100 h-100" >
            </div>
            <div class="match-table__main-group-stage-title" v-if="!isMobile && isWorldCup"><span>International | </span>FIFA World Cup 2022 &trade;<div class="home-match__country" v-if="isMobile">International</div></div>
            <div class="match-table__main-group-stage-title" v-if="isUefa"><span>UEFA Euro 2024</span></div>
        </div>
        <div class="match-table__main-group-stage-title">Group Stage</div>
        <div v-show="!isLoading">
            <div class="match-table__wrapper" v-for="(o, index) in matchTableModified" :key="index">
                <div class="match-table__header">{{o.tableName.split("-")[1]}}</div>
                <div class="match-table" >
                    <div  class="display-flex">
                        <!-- <div class="match-table__index">#</div> -->
                        <div class="match-table__club">              
                            <div>{{$t('TEAM')}}</div>
                        </div>
                    </div>
                    <div class="match-table__score">
                        <div>MP</div>
                        <div>W</div>
                        <div>D</div>
                        <div>L</div>
                        <div>F</div>
                        <div>A</div>
                        <div>+-</div>
                        <div>P</div>
                    </div>   
                </div>

                <!-- <Navigation v-for="(o, index) in o.total" :key="index" :id="o.teamId" :category="'team'" :name="o.teamName"  class="match-table"> -->
                <div class="match-table" v-for="(team, index) in o.total" :key="index">
                    <div class="display-flex-center">
                        <div class="match-table__index" :class="[isUefa && (index === 0 || index === 1) ? 'color-1' : (o.thirdQualified) ? 'color-2' : 'color-sp-1', team.promotionColor]">{{team.qualified}}</div>
                        <div class="match-table__club">                   
                            <div class="match-table__club-icon ">
                                <Icon  :logoUrl=team.logo ></Icon>
                                </div>
                            <div>{{team.teamName}}</div>
                        </div>
                    </div>
                    <div class="match-table__score">
                        <div>{{team.gamesPlayed === null? '-' : team.gamesPlayed}}</div>
                        <div>{{team.gamesWon === null? '-' : team.gamesWon}}</div>
                        <div>{{team.gamesDrawn === null? '-' : team.gamesDrawn}}</div>
                        <div>{{team.gamesLost === null? '-' : team.gamesLost}}</div>
                        <div>{{team.gamesScored === null? '-'  :team.gamesScored}}</div>
                        <div>{{team.gamesAgainst === null? '-' : team.gamesAgainst}}</div>
                        <div>{{team.goalsDifference === null? '-' : team.goalsDifference}}</div>
                        <div>{{team.points === null? '-' : team.points}}</div>
                    </div> 
                </div>
                <!-- </Navigation> -->

                <div class="no-data" v-show="isShowNoData">{{$t("NO_TABLE")}}</div>

                <!-- <div class="match-table__tips-wrapper" v-for="(o, index) in promotionList" :key="index">
                    <div class="match-table__tips" >
                        <div class="match-table__index" :class="o.color">#</div>
                        <div>{{o.value}}</div>
                    </div>
                </div> -->

            </div>

            <div class="match-table__tips-wrapper">
                <div class="match-table__tips" >
                    <div class="match-table__index color-1">#</div>
                    <div>{{ $t('ADVANCE_TO_ROUND_OF_16') }} </div>
                </div>
            </div>

            <div class="match-table__tips-wrapper" v-show="!isWorldCup">
                <div class="match-table__tips" >
                    <div class="match-table__index color-sp-1">#</div>
                    <div>{{ $t('POSSIBLE_QUALIFICATION_FOR_THE_NEXT_STAGE') }} </div>
                </div>
            </div>
            <div class="match-table__tips-wrapper" v-show="!isWorldCup">
                <div class="match-table__tips" >
                    <div class="match-table__index color-2">#</div>
                    <div>{{ $t('ADVANCE_TO_THE_KNOCKOUT_STAGE') }} </div>
                </div>
            </div>
        </div>
        <Loading v-show="isLoading"></Loading>

    </div>
  
</template>

<script>

import Loading from '@/components/indicator/Loading.vue'
import config from '@/js/config.js'

import {mapGetters,mapActions} from 'vuex'
export default {
    components:{
        Loading
    },
    props: {
        matchTableListData: {
            type: Array
        },
        matchTableTipsList: {
            type: Array
        },
        isShowTabs: {
            type: Boolean,
            default:true
        },
        type: {
            type: String,
             default:'total'
        },
        params:{
            type: String,
        },
        leagueId:{
            type: String,
        },

    },
 	computed: {
			...mapGetters([
                'isMobile',
                'matchDetailScore',
                'currentLocale',
                'currentSportType',
                'isWorldCup',
                'isUefa'
			]),
            matchTableModified() {
                if (this.isWorldCup || this.isUefa) {
                    this.matchTableList.forEach(group => {
                        group.total.forEach((team, i) => {
                            if ((i <= 2) && !this.isWorldCup) {
                                team.qualified = "#";
                            } else {
                                team.qualified = (i == 0 || i == 1) ? "#" : "";
                            }
                        });
                    });

                    return this.matchTableList;
                }
            }
		},
    data() {
        return {
                s3ImgUrl:config.s3ImgUrl,
                isLoading:false,
                isShowNoData:false,
                tabs:[
                    {
                        name:'All',
                        id:'',
                        selected:true,
                    },
                    {
                        name:'Home',
                        id:'1',
                        selected:false,
                    },
                    {
                        name:'Away',
                        id:'2',
                        selected:false,
                    },
                ],
                matchTableList:[],                
            }
    },

        watch:{
            params:{
                deep:true,
                handler(newVal,oldVal) {//pass param and get data from api here
                    this.getSoccerLeagueRankingData(newVal);
                }
            },
              matchTableListData:{// direct pass data to render
                deep:true,
                handler(newVal,oldVal) {  
                    this.matchTableList = newVal;//newVal[this.type]
                    this.promotionList =  newVal['promotionList'];
                }
            }
        },

    mounted() {
       // debugger;
     //  this.matchTableList =  this.matchTableListData;
        //this.getSoccerLeagueRankingData();
    },
   
    methods: {
        	...mapActions([
				"getSoccerLeagueRanking",
                "getMatchDetailScore",
                "getMatchDetailScoreAsync",
			]),


            async getSoccerLeagueRankingData(leagueIdList){
          
                let params = {
                    leagueIdList : leagueIdList
                }
                this.isLoading = true;
              
                const result = await this.getSoccerLeagueRanking(params);
                this.matchTableList = result.result;

                if (this.matchTableList.length === 0) {
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false;
                }

                this.isLoading = false;
            //    if (typeof this.matchDetailScore.leagueId === "undefined") {
                  
            //           let matchId = this.$route.params.matchId

            //        const results = await this.getMatchDetailScoreAsync({
            //             matchId:matchId,
            //             timeZone:this.$tools.getCurrentTimeZone()
            //         })
                   
            //           let params = {
            //             leagueId : results.result.leagueId
            //         }
                    
            //          const result = await this.getSoccerLeagueRanking(params);
            //         this.matchTableList = result.result;
            //    } else {
            //          let params = {
            //             leagueId : this.matchDetailScore.leagueId
            //         }
                    
            //          const result = await this.getSoccerLeagueRanking(params);
            //         this.matchTableList = result.result;
            //    }
              
               
              
            },

            prevBtnMenuLeagueTableListClick(){
                this.getSoccerLeagueRanking({
                    "leagueId":''
                }).then(res => {
                this.matchTableList =  res.result;
                }).catch(res => {
                    debugger;
                // this.$store.dispatch('VerifiFailure', res);
                })
            },
            selectTabs(o) {
                this.tabs.forEach(x=>{
                    x.selected = false
                });
                o.selected = true;
                    this.isLoading=true;
                // this.matchObj=[];
                    setTimeout(() => {
                    this.$nextTick(() => {
                        this.isLoading = false
                    })       
                    }      
                    , 800);
            },
      
    }
}
</script>
<style scoped>
    .match-table__main-group-stage-title {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    .match-table__wrapper {
        margin-bottom: 1.5rem;
    }
    .match-table__header-country__wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .88rem;
        margin-bottom: .4rem;
    }
    .match-table__header{        
        background-color: #212B30;
        font-weight: 700;
        font-size: 0.875rem;
        padding: 0.875rem 0.75rem;
    }
    .match-table {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color:#CFD9DE;
        /* border-bottom:1px solid #212B30; */
        padding: .5rem 0.8rem;
        font-size: .6875rem;
        position: relative;
    }
    .match-table::after {
        content: '';
        width: calc(100% - 1.6rem);
        border-bottom: 1px solid #212B30;
        position: absolute;
        bottom: 0;
    }
    .match-table:last-of-type::after {
        border-bottom: none;
    }
    .match-table.selected{
        background-color: #212B30;
        color: white;
        font-weight: 700;
    }
    .match-table:hover{
      background-color: var(--bg-hover);  
    }
    .match-table:hover::after{
        border-bottom: 1px solid var(--bg-hover);
    }
    .match-table__index{
        margin-right: .3rem;
        width: 1.1rem;
    }
    .color-1{
        color:#5CD66C;
    }
    .color-2{
        color:#2E8FEF;
    }
    .color-3{
        color:#f7eb83;
    }
    .color-4{
        color:#ff9800;
    }
    .color-5{
        color:#673ab7;
    }
    .color-p{
        color:#ffc0cb;
    }
    .color-r{
        color: #d81f0d;
    }
    .color-sp-1  {
        color: #ED9C5A;
    }
    .match-table__club{
        display:flex;
        align-items: center;
        flex:2;
        max-width: 8rem;
    }

    .match-table__club-icon{
        margin-right: .5rem;
    }
    .match-table__score{
        display:flex;
        width:10rem;
        justify-content: space-between;
        text-align: center;
    }
    .match-table__score>div{
        flex:1;
        margin: 0 .25rem;
    
    }
    .match-table__tips-wrapper{
        background-color: var(--color-theme);
        color: #CFD9DE;
    }
    .match-table__tips{
        display: flex;
        flex-direction: row;
        margin: 0 .8rem;
        padding: .5rem 0;
        font-size: .7rem;
    }

    @media (max-width: 768px) {
        .match-table__main-group-stage-title {
            font-size: 0.875rem;
        }
    }
</style>
